import React from 'react';
import './NewSection.css'; 
import logo from "../images/microwavefire.gif"; 

const NewSection = () => {
  return (
    <section className="new-section">
      <div className="content">
        <div className="text">
          <h2>Security</h2>
          <p>- Liquidity will be burned</p>
          <p>- Mint will be renounced</p>
          <p>- Metadata will be fixed</p>
        </div>
        <div className="image">
          <img src={logo} alt="Example" />
        </div>
      </div>
    </section>
  );
};

export default NewSection;
