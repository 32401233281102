import React from 'react';
import './SecondSection.css';
import logo from "../images/microwave.gif"

const SecondSection = () => {
  return (
    <section className="second-section">
      <img src={logo} alt="solana gif" />
      <img src={logo} alt="solana gif" />
      <img src={logo} alt="solana gif" />
    </section>
  );
};

export default SecondSection;
