import React from 'react';
import './FirstSection.css';

const FirstSection = () => {
  return (
    <section className="first-section">
      <div className="content">
        <div className="image">
          <h2>NICK THE TICK</h2>
          <a href="https://raydium.io/" target='_blank' rel="noopener noreferrer">
            <div className='image-container' />
          </a>
          <h3>The new superhero on sol is the new ticker too</h3>
        </div>
      </div>
    </section>
  );
};

export default FirstSection;
