// AboutSection.js
import React from 'react';
import './AboutSection.css'; // Importa el CSS renombrado
import microwave from "../images/microwave1.png"
import sale from "../images/microwave_SALE.png"

const AboutSection = () => {
    return (

        <section className="about-section">
            <div className="leaf">
                <div>  <img src={microwave} height="75px" width="75px"></img></div>
                <div><img src={microwave} height="75px" width="75px"></img></div>
                <div>  <img src={microwave} height="75px" width="75px" ></img></div>
                <div><img src={microwave} height="75px" width="75px"></img></div>
                <div> <img src={microwave} height="75px" width="75px"></img></div>
                <div>   <img src={microwave} height="75px" width="75px"/></div>
                <div><img src={microwave} height="75px" width="75px"/></div>

            </div>

            <div className="leaf leaf1">
                <div>  <img src={microwave} height="75px" width="75px"></img></div>
                <div><img src={microwave} height="75px" width="75px"></img></div>
                <div>  <img src={microwave} height="75px" width="75px" ></img></div>
                <div><img src={microwave} height="75px" width="75px"></img></div>
                <div> <img src={microwave} height="75px" width="75px"></img></div>
                <div>   <img src={microwave} height="75px" width="75px"/></div>
                <div><img src={microwave} height="75px" width="75px"/></div>

            </div>

            <div className="leaf leaf2">
                <div>  <img src={microwave} height="75px" width="75px"></img></div>
                <div><img src={microwave} height="75px" width="75px"></img></div>
                <div>  <img src={microwave} height="75px" width="75px" ></img></div>
                <div><img src={microwave} height="75px" width="75px"></img></div>

                <div> <img src={microwave} height="75px" width="75px"></img></div>
                <div>   <img src={microwave} height="75px" width="75px"/></div>
                <div><img src={microwave} height="75px" width="75px"/></div>

            </div>
            <div className="about-content">
            <a href="https://raydium.io/"  target='_blank' rel="noopener noreferrer">
                <img src={sale} alt="sale" className="center-image" />
            </a>
            </div>
        </section>
    );
};

export default AboutSection;
